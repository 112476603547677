export const store = {
  personalDesc: `Hello! I'm Ijeri Omitogun, a Software Engineer with a strong focus on creating user-friendly and visually engaging web applications. My journey started with a love for video games, which led me to discover my passion for web development. <br/> Over the years, I've worked on innovative projects at Nauticus Robotics and Google, honing my skills in designing intuitive user experiences and ensuring application performance and accessibility. <br/>
  While I specialize in frontend development, I'm always eager to explore new challenges in software engineering. If you're looking for someone to bring your vision to life, let's connect and create something extraordinary together.`,
  experiences: [
    {
      companyName: "Data Annotation",
      startDate: "April 2024",
      endDate: "Present",
      jobTitle: "AI Trainer (Contract)",
      logoPath: "data-annotations-logo.jpg",
      companyUrl: "https://www.dataannotation.tech/",
      description:
        "I trained AI chatbots to enhance their coding capabilities and ensure high-quality code generation. I evaluated various coding problems to measure the AI’s progress and performance. I authored clear code snippets and explanations, and validated AI-generated code and images to meet project standards. My work involved ensuring correctness, performance, and alignment with project requirements.",
    },
    {
      companyName: "Google LLC",
      startDate: "November 2021",
      endDate: "March 2024",
      jobTitle: "Software Engineer",
      logoPath: "google-logo.png",
      companyUrl: "https://about.google/",
      description:
        "I developed front-end components with Angular, TypeScript, and Java, ensuring web accessibility compliance, and played a key role in designing and implementing Google's $5 billion Buying Hub. I led product epics, collaborated with cross-functional teams, and contributed to sub-projects like Supplier Nexus and Contracting Hub. I also trained contractors, mentored colleagues, and earned certifications in TypeScript, Angular, and Web Accessibility.",
    },
    {
      companyName: "Nauticus Robotics",
      startDate: "May 2019",
      endDate: "June 2021",
      jobTitle: "Software Engineer",
      logoPath: "nauticus-robotics-logo.jpeg",
      companyUrl: "https://nauticusrobotics.com/",
      description:
        "I developed front-end components with JavaScript and Vue.js for robotic control interfaces and contributed to UX design and automation for products like the Olympic Arm and Aquanaut. I assisted in offshore software deployments, conducted customer demos, and worked on the HaloGuard safety system to improve safety. I implemented an Automated User Testing Suite with Selenium in Git CI pipelines and maintained Python and Vue code repositories.",
    },
  ],
  programmingLanguages: [
    "Javascript",
    "Typescript",
    "Python",
    "Angular",
    "Vue",
    "HTML5",
    "CSS",
    "Node",
    "C#",
    "ROS",
  ],
  devTools: [
    "VS Code",
    "Jasmine",
    "Mocha/Chai",
    "Node",
    "Selenium",
    "Visual Studio",
    "Intellij",
    "Docker",
    "Unity3D",
    "Git",
    "Gitlab CI",
    "Linux",
    "MacOS",
    "Windows",
  ],
  endorsements: [
    {
      name: "Mayuri Raja",
      title: "Software Engineer at Google",
      imageSrc: require("@/assets/about-me-images/mayuri-profile-pic.jpeg"),
      text: "Ijeri is hardworking and driven to solve even the toughest of problems. His curiosity and love of programming show in the quality and depth of his work.",
    },
    {
      name: "Blake DeBenon",
      title: "Senior Engineer at Google",
      imageSrc: require("@/assets/about-me-images/blake-profile-pic.jpeg"),
      text: "I worked with Ijeri at Google on an ambitious project that led to billions in savings for Google. Ijeri made an enormous impact in helping the project succeed by working locking down and implementing requirements. Ijeri was an effective and reliable teammate. I would absolutely want to work with him again!",
    },
    {
      name: "Jide Akinyode",
      title: "VP of Engineering at Nauticus Robotics",
      imageSrc: require("@/assets/about-me-images/jide-profile-pic.jpeg"),
      text: "Ijeri worked closely under me. He grew from a rookie intern to a full fledged engineer. He was a crucial part of launching several Nauticus Robotics products: Haloguard, Olympic Arm, and Aquanaut. He went above and beyond for the company. Any team would be lucky to have him",
    },
    {
      name: "Thanh Truong",
      title: "Software Engineer at Google",
      imageSrc: require("@/assets/about-me-images/thanh-profile-pic.jpeg"),
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur.",
    },
    {
      name: "Person 5",
      title: "Engineer at Company",
      imageSrc: "",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur.",
    },
  ],
  productTeams: [
    {
      title: "Buying Hub by Google",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur. Nam maximus consequat metus eu malesuada. Pellentesque aliquet, lectus id maximus laoreet, nulla libero fermentum risus, varius iaculis arcu turpis in nisl. Curabitur accumsan a ligula vitae ullamcorper. Nulla eget ultrices sapien, at auctor dui. Aenean sollicitudin, metus sed.",
      media: [require("@/assets/project-images/purchase_buying_hub.png")],
      techStack: ["Angular", "Typescript", "Mocha/Chai"],
    },
    {
      title: "Olympic Arm by Nauticus Robotics",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur. Nam maximus consequat metus eu malesuada. Pellentesque aliquet, lectus id maximus laoreet, nulla libero fermentum risus, varius iaculis arcu turpis in nisl. Curabitur accumsan a ligula vitae ullamcorper. Nulla eget ultrices sapien, at auctor dui. Aenean sollicitudin, metus sed.",
      media: [
        require("@/assets/project-images/olympic-arm.jpg"),
        require("@/assets/project-images/olympic-arm.mp4"),
        require("@/assets/project-images/mock-oly-arm.mp4"),
        require("@/assets/project-images/oly-arm-tool.mp4"),
      ],
      productUrl: "https://nauticusrobotics.com/olympic-arm/",
      techStack: ["Vue.js", "ROS", "Python", "Docker"],
    },
    {
      title: "Supplier Nexus by Google",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur. Nam maximus consequat metus eu malesuada. Pellentesque aliquet, lectus id maximus laoreet, nulla libero fermentum risus, varius iaculis arcu turpis in nisl. Curabitur accumsan a ligula vitae ullamcorper. Nulla eget ultrices sapien, at auctor dui. Aenean sollicitudin, metus sed.",
      media: [require("@/assets/project-images/supplier_buying_hub.png")],
      techStack: ["Angular", "Typescript", "Mocha/Chai"],
    },
    {
      title: "Haloguard by Nauticus Robotics & TransOcean ",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur. Nam maximus consequat metus eu malesuada. Pellentesque aliquet, lectus id maximus laoreet, nulla libero fermentum risus, varius iaculis arcu turpis in nisl. Curabitur accumsan a ligula vitae ullamcorper. Nulla eget ultrices sapien, at auctor dui. Aenean sollicitudin, metus sed.",
      media: [require("@/assets/project-images/haloguard.png")],
      productUrl:
        "https://www.rigzone.com/news/wire/transocean_deploys_drill_floor_safety_tech-12-feb-2021-164599-article/",
      techStack: ["Vue", "Javascript", "ROS", "Python"],
    },
    {
      title: "toolKITT by Nauticus Robotics",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec arcu mauris. Cras congue eu magna ut efficitur. Donec nec elit a massa cursus consectetur. Nam maximus consequat metus eu malesuada. Pellentesque aliquet, lectus id maximus laoreet, nulla libero fermentum risus, varius iaculis arcu turpis in nisl. Curabitur accumsan a ligula vitae ullamcorper. Nulla eget ultrices sapien, at auctor dui. Aenean sollicitudin, metus sed.",
      media: [require("@/assets/project-images/aquanaut-commander.webp")],
      productUrl: "https://nauticusrobotics.com/toolkitt/",
      techStack: ["ROS", "Python"],
    },
  ],
  projects: [
    {
      projectName: "LeetCode Practice",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [require("@/assets/project-images/leetcode.png")],
      githubUrl: "https://github.com/ijeriomit/leetcode-practice",
      skills: ["Javascript"],
    },
    {
      projectName: "ROS Video Recorder",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [require("@/assets/project-images/ROS.png")],
      githubUrl: "https://github.com/ijeriomit/ROS-Video-Recorder",
      skills: ["ROS", "Python", "OpenCV"],
    },
    {
      projectName: "Python Experiments",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [require("@/assets/project-images/python.jpeg")],
      githubUrl: "https://github.com/ijeriomit/Python",
      skills: ["Python"],
    },
    {
      projectName: "SEP-Agent Creation",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [require("@/assets/project-images/leetcode.png")],
      githubUrl: "https://github.com/ijeriomit/SEP-Agent-Creation",
      skills: ["React", "Sindarin", "LLM"],
    },
    {
      projectName: "LAB",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [require("@/assets/project-images/LAB.png")],
      githubUrl: "https://github.com/ijeriomit/LAB",
      skills: ["C#", "Unity 3D"],
    },
    {
      projectName: "Epoch",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [
        require("@/assets/project-images/player-jumping.png"),
        require("@/assets/project-images/epoch-short.mp4"),
      ],
      githubUrl: "https://github.com/ijeriomit/Epoch",
      skills: ["C#", "Unity 3D"],
    },
    {
      projectName: "Personal Portfolio",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      media: [
        require("@/assets/project-images/portfolio.png"),
        require("@/assets/project-images/portfolio-2.png"),
      ],
      githubUrl: "https://github.com/ijeriomit/portfolio-site-2.0",
      skills: ["Vue.js", "SCSS"],
    },
  ],
};
